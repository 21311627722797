import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { CookieService } from 'ngx-cookie-service';

import { AppStorageProvider } from '../common/app-storage.service';
import { LoginProvider } from '../login/login.service';

/**
 * @name SessionProvider
 * @desc Servicio para manejo de variable de sesión en localStorage.
 */
@Injectable({
  providedIn: 'root'
})
export class SessionProvider {

  key: string = '';
  expirationNotifier = new Subject<boolean>();

  constructor(private appStorage: AppStorageProvider, private cookieServ: CookieService,
    private loginProvider: LoginProvider) { }

  /**
   * @name create
   * @desc Método para crear variable de sesión.
   * @param {String} sessionToken token de la sesion
   * @param {String} sessionExpiration fecha de expiracion del token
   */
  create(sessionToken: string, sessionExpiration: string) {
    const session = {
      date: sessionExpiration,
      token: sessionToken
    };
    this.appStorage.put('session', session);
    this.key = sessionToken;
  }

  /**
   * @name reset
   * @desc Método para borrar token de la ram.
   */
  resetAll() {
    this.key = null;
    const langCurrent = localStorage.getItem('lang');
    this.appStorage.get("csiErrors").then(data => {
      this.appStorage.get('themeDark:active').then((isEnabled: boolean) => {

        const errores = data;
        this.appStorage.removeAll();
        if (errores) {
          this.appStorage.put("csiErrors", errores);
        }

        localStorage.setItem('lang', langCurrent);
        this.appStorage.put("themeDark:active", isEnabled);
      });
    });
  }

  /**
   * @name load
   * @desc Método para obtener variable de sesión.
   */
  load() {
    return new Promise<void>((resolve, reject) => {
      this.appStorage.get('session').then((data: any) => {
        if (data) {
          let expirationTime = Date.parse(data.date) || 0;
          if (expirationTime > new Date().getTime()) {
            this.key = data.token;
          } else {
            this.key = '';
          }
        }
        resolve();
      })
    });
  }

  /**
   * @name isAuthenticated
   * @desc Método para determinar si hay una sesión activa en la app
   */
  isAuthenticated() {
    let cookie = Cookie.get('oRt4v.s7fJL');

    if (cookie !== '' && this.key !== '') {
      return true;
    }

    return false;
  }

  /**
   * @name tokenExpired
   * @desc Método para eliminar la sesion cuando se haya detectado que el token almacenado expiró
   */
  tokenExpired() {
    this.loginProvider.logout().subscribe(
      (data: any) => {
        this.resetAll();
        this.expirationNotifier.next();
      },
      (error: HttpErrorResponse) => {
        this.resetAll();
        this.expirationNotifier.next();
      }
    );
  }

  /**
   * @name saveBearerCookie
   * @desc Método para guardar y generar la cookie de Bearer en la app
   */
  saveBearerCookie(tokenBearer: string, hostanme: string = '') {
    this.cookieServ.set('Bearer-no-domain', tokenBearer, { secure: true, sameSite: 'None', path: '/' });
    this.cookieServ.set('Bearer', tokenBearer, { secure: true, sameSite: 'None', path: '/', domain: '.csigroup.com.mx' });

    if (hostanme != '') {
      this.cookieServ.set('Bearer-dynamic', tokenBearer, { secure: true, sameSite: 'None', path: '/', domain: hostanme });
    }
    // Cookie.set('Bearer', tokenBearer, 2, '/', '.csigroup.com.mx');

    if (this.cookieServ.check('Bearer') || this.cookieServ.check('Bearer-no-domain')) {
      return true;
    }
    return false;
  }

  /**
   * @name saveSessionTokenCookie
   * @desc Método para guardar y generar la cookie de oRt4v.s7fJL en la app
   */
  saveSessionTokenCookie(tokenSession: string, hostanme: string = '') {
    this.cookieServ.set('oRt4v.s7fJL-no-domain', tokenSession, { secure: true, sameSite: 'None', path: '/' });
    this.cookieServ.set('oRt4v.s7fJL', tokenSession, { secure: true, sameSite: 'None', path: '/', domain: '.csigroup.com.mx' });

    if (hostanme != '') {
      this.cookieServ.set('oRt4v.s7fJL-dynamic', tokenSession, { secure: true, sameSite: 'None', path: '/', domain: hostanme });
    }

    if (this.cookieServ.check('oRt4v.s7fJL') || this.cookieServ.check('oRt4v.s7fJL-no-domain')) {
      return true;
    }
    return false;
  }

}
