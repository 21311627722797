import { Injectable } from '@angular/core';

import { Events } from "src/app/providers/common/events.service";
import { DashboardsService } from '../dashboards/dashboards.service';
import { AppStorageProvider } from '../common/app-storage.service';

@Injectable({
  providedIn: 'root'
})
export class FavoriteHomeProvider {

  private dashboardsFinancierosActive: boolean = false;
  private dashboardsOperativosActive: boolean = false;
  private dashboardsAMDFActive: boolean = false;
  private dashboardMazda: boolean = false;
  private dashboardBMW: boolean = false;

  private dahsboardCountActive: number = 0;
  private favDashboard: string = "home";

  public favoriteHome: string = 'Home';
  public favoriteURL: string = '';

  constructor(
    private dashboardsService: DashboardsService,
    private events: Events, private storage: AppStorageProvider
  ) {
    this.getFavouriteDashboard();
    this.initDashboards();
  }

  getFavorite() {
    // console.log("then", this.favDashboard, this.dashboardsFinancierosActive, this.dashboardsOperativosActive, this.dashboardsAMDFActive, this.dashboardMazda, this.dashboardBMW);

    if (this.dahsboardCountActive >= 2) {
      console.log("active more than one");
      this.dashboardsService.getDashboardFavorito().then((dashboard: string) => {
        this.events.publish("menu:dashboards:current", dashboard);
        this.favDashboard = dashboard;
      }).catch(err => {
        console.error(err);
      });
    } else if (this.dashboardsFinancierosActive) {
      // console.log("financiero");
      this.favoriteHome = "financiero";
      this.favoriteURL = "dashboard/financiero";
    } else if (this.dashboardsOperativosActive) {
      // console.log("operativo");
      this.favoriteHome = "operativo";
      this.favoriteURL = "dashboard/operativo";
    } else if (this.dashboardsAMDFActive) {
      // console.log("amdf");
      this.favoriteHome = "amdf";
      this.favoriteURL = "dashboard/amdf";
    } else if (this.dashboardMazda) {
      // console.log("mazda");
      this.favoriteHome = "mazda";
      this.favoriteURL = "dashboard/mazda";
    } else if (this.dashboardBMW) {
      // console.log("bmw");
      this.favoriteHome = "bmw";
      this.favoriteURL = "dashboard/bmw";
    }

    return this.favoriteHome;
  }

  getFavouriteDashboard() {
    this.dashboardsService.getDashboardFavorito().then((dashboard: string) => {
      this.favDashboard = dashboard;
    }).catch(err => {
      console.error(err);
    });
  }

  async initDashboards() {
    let active = await this.dashboardsService.areActivated();
    this.dashboardsFinancierosActive = active;
    let activeOp = await this.dashboardsService.areActivatedOperativo();
    this.dashboardsOperativosActive = activeOp;
    let activeAMDF = await this.dashboardsService.areActivatedDashboard('AMDF');
    this.dashboardsAMDFActive = activeAMDF;
    let activeMazda = await this.dashboardsService.areActivatedDashboard('Mazda');
    this.dashboardMazda = activeMazda;
    let activeBMW = await this.dashboardsService.areActivatedDashboard('BMW');
    this.dashboardBMW = activeBMW;

    this.dahsboardCountActive = 0;

    if (this.dashboardsFinancierosActive)
      this.dahsboardCountActive++;
    if (this.dashboardsOperativosActive)
      this.dahsboardCountActive++;
    if (this.dashboardsAMDFActive)
      this.dahsboardCountActive++;
    if (this.dashboardMazda)
      this.dahsboardCountActive++;
    if (this.dashboardBMW)
      this.dahsboardCountActive++;

  }

  getUserFavoriteHomePage() {
    let Bit_Default = 0;
    this.storage.get('userMenu').then((data: any) => {
      if (data.Preferencias) {
        console.log('Preferencias: ', JSON.stringify(data.Preferencias));
        for (const item of data.Preferencias) {

          switch (item.Id_Preferencia) {
            case '1':
              Bit_Default = item.Bit_Default ? 2 : Bit_Default;
              break;
            case '3':
              Bit_Default = item.Bit_Default ? 3 : Bit_Default;
              break;
            case '4':
              Bit_Default = item.Bit_Default ? 4 : Bit_Default;
              break;
            case '5':
              Bit_Default = item.Bit_Default ? 5 : Bit_Default;
              break;
            case '8':
              Bit_Default = item.Bit_Default ? 8 : Bit_Default;
              break;
            case '9':
              Bit_Default = item.Bit_Default ? 9 : Bit_Default;
              break;
            case '10':
              Bit_Default = item.Bit_Default ? 10 : Bit_Default;
              break;
            case '11':
              Bit_Default = item.Bit_Default ? 11 : Bit_Default;
              break;
            case '12':
              Bit_Default = item.Bit_Default ? 12 : Bit_Default;
              break;
            case '13':
              Bit_Default = item.Bit_Default ? 13 : Bit_Default;
              break;
            case '15':
              Bit_Default = item.Bit_Default ? 15 : Bit_Default;
              break;
            case '16':
              Bit_Default = item.Bit_Default ? 16 : Bit_Default;
              break;
            case '17':
              Bit_Default = item.Bit_Default ? 17 : Bit_Default;
              break;
            case '20':
              Bit_Default = item.Bit_Default ? 20 : Bit_Default;
              break;
            case '21':
              Bit_Default = item.Bit_Default ? 21 : Bit_Default;
              break;
            case '22':
              Bit_Default = item.Bit_Default ? 22 : Bit_Default;
              break;
            case '23':
              Bit_Default = item.Bit_Default ? 23 : Bit_Default;
              break;
            case '24':
              Bit_Default = item.Bit_Default ? -11 : Bit_Default;
              if (item.Bit_Default) this.events.publish("bscDashboardPage:name", 'csi');

              break;
            case '25':
              Bit_Default = item.Bit_Default ? -14 : Bit_Default;
              if (item.Bit_Default) this.events.publish("bscDashboardPage:name", 'sunus');

              break;
            case '26':
              Bit_Default = item.Bit_Default ? -10 : Bit_Default;
              if (item.Bit_Default) this.events.publish("bscDashboardPage:name", 'cpv');

              break;
            case '27':
              Bit_Default = item.Bit_Default ? -12 : Bit_Default;
              if (item.Bit_Default) this.events.publish("bscDashboardPage:name", 'csid');

              break;
            case '28':
              Bit_Default = item.Bit_Default ? -13 : Bit_Default;
              if (item.Bit_Default) this.events.publish("bscDashboardPage:name", 'csix');

              break;
            case '29':
              Bit_Default = item.Bit_Default ? 24 : Bit_Default;
              break;
            default:
              break;
          }
        }
      }

      if (data.Dashboards) {
        console.log('Preferencias: ', JSON.stringify(data.Dashboards));
        for (const dashboard of data.Dashboards) {

          switch (dashboard.Id_Dashboard) {
            case '1': // Dashboard Financiero 
              Bit_Default = dashboard.Bit_Default_Home ? -1 : Bit_Default;
              if (dashboard.Bit_Default_Home) this.events.publish("dashboardPage:name", 'financiero');

              break;
            case '2': // Dashboard Operativo 
              Bit_Default = dashboard.Bit_Default_Home ? -2 : Bit_Default;
              if (dashboard.Bit_Default_Home) this.events.publish("dashboardPage:name", 'operativo');

              break;
            case '3': // Dashboard AMDF 
              Bit_Default = dashboard.Bit_Default_Home ? -3 : Bit_Default;
              if (dashboard.Bit_Default_Home) this.events.publish("dashboardPage:name", 'amdf');

              break;
            case '4': // Dashboard Mazda 
              Bit_Default = dashboard.Bit_Default_Home ? -4 : Bit_Default;
              if (dashboard.Bit_Default_Home) this.events.publish("dashboardPage:name", 'mazda');

              break;
            case '5': // Dashboard BMW 
              Bit_Default = dashboard.Bit_Default_Home ? -5 : Bit_Default;
              if (dashboard.Bit_Default_Home) this.events.publish("dashboardPage:name", 'bmw');

              break;
            default:
              break;
          }
        }
      }

      this.events.publish("homePage:Custom", Bit_Default);
    });
  }
}
