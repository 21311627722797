// PROD
// export var apiURL = 'https://h5067lrq1k.execute-api.us-east-1.amazonaws.com/csi-aws-csiapp/v1/csiapp'; // Production
// export var apiTestCatalogos =
//   'https://h5067lrq1k.execute-api.us-east-1.amazonaws.com/csi-aws-csiapp/v1/catalogos'; //Intercambios Test API
// export var apiTestIntercambios =
//   'https://h5067lrq1k.execute-api.us-east-1.amazonaws.com/csi-aws-csiapp/v1/intercambios'; //Intercambios Test API
// export var apiAmazon =
//   'https://h5067lrq1k.execute-api.us-east-1.amazonaws.com/csi-aws-csiapp/v1/intercambios';
// export var apiUsuario =
//   'https://h5067lrq1k.execute-api.us-east-1.amazonaws.com/csi-aws-csiapp/v1/Usuarios';
// var baseWssUrl = 'https://h5067lrq1k.execute-api.us-east-1.amazonaws.com/csi-aws-csiapp'; // Prod

// NEW PROD
export var apiURL = 'https://apis.csigroup.com.mx/quantum/v1/csiapp'; // Production
export var apiTestCatalogos = 'https://apis.csigroup.com.mx/quantum/v1/catalogos'; //Intercambios Test API
export var apiTestIntercambios = 'https://apis.csigroup.com.mx/quantum/v1/intercambios'; //Intercambios Test API
export var apiAmazon = 'https://apis.csigroup.com.mx/quantum/v1/intercambios';
export var apiUsuario ='https://apis.csigroup.com.mx/quantum/v1/Usuarios';
var baseWssUrl = 'https://apis.csigroup.com.mx/quantum'; // Prod

// DEV
// export var apiURL = 'https://vs5858pe75.execute-api.us-east-1.amazonaws.com/Prod/v1/csiapp'; // Production
// export var apiTestCatalogos = 'https://vs5858pe75.execute-api.us-east-1.amazonaws.com/Prod/v1/catalogos'; //Intercambios Test API
// export var apiTestIntercambios = 'https://vs5858pe75.execute-api.us-east-1.amazonaws.com/Prod/v1/intercambios'; //Intercambios Test API
// export var apiAmazon = 'https://vs5858pe75.execute-api.us-east-1.amazonaws.com/Prod/v1/intercambios';
// export var apiUsuario = 'https://vs5858pe75.execute-api.us-east-1.amazonaws.com/Prod/v1/Usuarios';
// var baseWssUrl = 'https://vs5858pe75.execute-api.us-east-1.amazonaws.com/Prod'; // Prod

// DEV
// export var apiURL = 'https://vs5858pe75.execute-api.us-east-1.amazonaws.com/Prod/v1/csiapp'; // QA
// export var apiTestCatalogos = 'https://89pla0sk38.execute-api.us-east-1.amazonaws.com/QA/v1/catalogos'; //Intercambios Test API
// export var apiTestIntercambios = 'https://89pla0sk38.execute-api.us-east-1.amazonaws.com/QA/v1/intercambios'; //Intercambios Test API
// export var apiAmazon = 'https://89pla0sk38.execute-api.us-east-1.amazonaws.com/QA/v1/intercambios'
// export var apiUsuario = 'https://89pla0sk38.execute-api.us-east-1.amazonaws.com/QA/v1/Usuarios';
// var baseWssUrl = 'https://89pla0sk38.execute-api.us-east-1.amazonaws.com/QA'; // QA

//UAT TEST
// export var apiURL = 'https://vs5858pe75.execute-api.us-east-1.amazonaws.com/Prod/v1/csiapp';
// export var apiTestCatalogos = 'https://vs5858pe75.execute-api.us-east-1.amazonaws.com/Prod/v1/catalogos'; //Intercambios
// export var apiTestIntercambios = 'https://vs5858pe75.execute-api.us-east-1.amazonaws.com/Prod/intercambios'; //Intercambios
// export var apiAmazon = 'https://vs5858pe75.execute-api.us-east-1.amazonaws.com/Prod/v1/intercambios';
// export var apiUsuario = 'https://vs5858pe75.execute-api.us-east-1.amazonaws.com/Prod/v1/Usuarios';
//  var baseWssUrl = 'https://vs5858pe75.execute-api.us-east-1.amazonaws.com/Prod'; //


export var consultasCivarUrl = baseWssUrl + "/v1/ConsultasCivar";
export var consultasMatrizUrl = baseWssUrl + "/v1/trafico";
export var alertasWssUrl = baseWssUrl + "/v1/Alarmas";
export var tractoresWssUrl = baseWssUrl + "/v1/Tractor";
export var bitacoraErroresUrl = baseWssUrl + "/v1/BitacoraErrores";
export var dashboardsUrl = baseWssUrl + "/v1/Dashboard";
export var dashboardBSCUrl = baseWssUrl + "/v1/DashboardBSC";
export var dashboardsAMDFUrl = baseWssUrl + '/v1/DashboardAMDF';
export var dashboardsMazdaUrl = baseWssUrl + '/v1/DashboardMazda';
export var dashboardsBMWUrl = baseWssUrl + '/v1/DashboardBMW';
export var patiosWssUrl = baseWssUrl + "/v1/Patios";
export var investorRelationsUrl = baseWssUrl + "/v1/csiapp/InvestorRelations"
export var CargarMenuCivar: boolean = true; //true para mostrar el menu civar.
export var downloadAndroid = '/storage/emulated/0/Download/';
export var idiomasUrl = baseWssUrl + '/v1/Usuarios';

export var appStoreiOS = "https://apps.apple.com/us/app/csi/id1230147514";
export var appStoreAndroid = "https://play.google.com/store/apps/details?id=com.sunustech.enterprise.app&pcampaignid=web_share";

var _prod: boolean = true;

export function getProdEnv() {
  return _prod;
}

export function setProdEnv(ambiente: boolean) {
  _prod = ambiente;
  switch (_prod) {
    case false:
      // UAT TEST
      apiURL = 'https://osbuat.csigroup.com.mx:7503/csiappUAT';
      apiTestCatalogos =
        'https://yyvrj3lxkc.execute-api.us-east-1.amazonaws.com/csi-aws-csiapp-uat/v1/catalogos'; //Intercambios
      apiTestIntercambios =
        'https://yyvrj3lxkc.execute-api.us-east-1.amazonaws.com/csi-aws-csiapp-uat/v1/intercambios'; //Intercambios
      apiAmazon =
        'https://yyvrj3lxkc.execute-api.us-east-1.amazonaws.com/csi-aws-csiapp-uat/v1/intercambios';
      apiUsuario =
        'https://yyvrj3lxkc.execute-api.us-east-1.amazonaws.com/csi-aws-csiapp-uat/v1/Usuarios';
      baseWssUrl =
        'https://yyvrj3lxkc.execute-api.us-east-1.amazonaws.com/csi-aws-csiapp-uat';
      CargarMenuCivar = false; //true para mostrar el menu civar.

      break;
    case true:
      // PROD
      // apiURL = 'https://h5067lrq1k.execute-api.us-east-1.amazonaws.com/csi-aws-csiapp/v1/csiapp';
      //   apiTestCatalogos = 'https://h5067lrq1k.execute-api.us-east-1.amazonaws.com/csi-aws-csiapp/v1/catalogos'; //Intercambios
      //   apiTestIntercambios = 'https://h5067lrq1k.execute-api.us-east-1.amazonaws.com/csi-aws-csiapp/intercambios'; //Intercambios
      //   apiAmazon = 'https://h5067lrq1k.execute-api.us-east-1.amazonaws.com/csi-aws-csiapp/v1/intercambios';
      //   apiUsuario = 'https://h5067lrq1k.execute-api.us-east-1.amazonaws.com/csi-aws-csiapp/v1/Usuarios';
      //   baseWssUrl = 'https://h5067lrq1k.execute-api.us-east-1.amazonaws.com/csi-aws-csiapp';

      //NEW PROD
      apiURL = 'https://apis.csigroup.com.mx/quantum/v1/csiapp';
      apiTestCatalogos = 'https://apis.csigroup.com.mx/quantum/v1/catalogos'; //Intercambios
      apiTestIntercambios = 'https://apis.csigroup.com.mx/quantum/intercambios'; //Intercambios
      apiAmazon = 'https://apis.csigroup.com.mx/quantum/v1/intercambios';
      apiUsuario = 'https://apis.csigroup.com.mx/quantum/v1/Usuarios';
      baseWssUrl = 'https://apis.csigroup.com.mx/quantum';


      // DEV
      // apiURL = 'https://vs5858pe75.execute-api.us-east-1.amazonaws.com/Prod/v1/csiapp';
      // apiTestCatalogos = 'https://vs5858pe75.execute-api.us-east-1.amazonaws.com/Prod/v1/catalogos'; //Intercambios
      // apiTestIntercambios = 'https://vs5858pe75.execute-api.us-east-1.amazonaws.com/Prod/intercambios'; //Intercambios
      // apiAmazon = 'https://vs5858pe75.execute-api.us-east-1.amazonaws.com/Prod/v1/intercambios';
      // apiUsuario = 'https://vs5858pe75.execute-api.us-east-1.amazonaws.com/Prod/v1/Usuarios';
      // baseWssUrl = 'https://vs5858pe75.execute-api.us-east-1.amazonaws.com/Prod'; //

      //DEV
      // apiURL = 'https://osbuat.csigroup.com.mx:7503/csiapp'; // QA
      // apiTestCatalogos = 'https://89pla0sk38.execute-api.us-east-1.amazonaws.com/QA/v1/catalogos'; //Intercambios Test API
      // apiTestIntercambios = 'https://89pla0sk38.execute-api.us-east-1.amazonaws.com/QA/v1/intercambios'; //Intercambios Test API
      // apiAmazon = 'https://89pla0sk38.execute-api.us-east-1.amazonaws.com/QA/v1/intercambios'
      // apiUsuario = 'https://89pla0sk38.execute-api.us-east-1.amazonaws.com/QA/v1/Usuarios';
      // baseWssUrl = 'https://89pla0sk38.execute-api.us-east-1.amazonaws.com/QA'; // QA

      break;
    default:
      break;
  }
  consultasCivarUrl = baseWssUrl + '/v1/ConsultasCivar';
  consultasMatrizUrl = baseWssUrl + '/v1/trafico';
  alertasWssUrl = baseWssUrl + '/v1/Alarmas';
  tractoresWssUrl = baseWssUrl + '/v1/Tractor';
  bitacoraErroresUrl = baseWssUrl + '/v1/BitacoraErrores';
  dashboardsUrl = baseWssUrl + '/v1/Dashboard';
  patiosWssUrl = baseWssUrl + '/v1/Patios';
}

// LOCAL
// export const apiURL = 'https://osbuat.csigroup.com.mx:7503/csiapp'; // QA

// export const apiTestCatalogos = 'http://localhost:64275/v1/catalogos'; //Intercambios Test API

// export const apiTestIntercambios = 'http://localhost:64275/v1/intercambios'; //Intercambios Test API

// export const apiAmazon = 'http://localhost:64275/v1/intercambios'

// export const apiUsuario = 'http://localhost:64275/v1/Usuarios'

// const baseWssUrl = 'http://localhost:64275/'; // QA

console.log(CargarMenuCivar);
export var Pass: string;
export var Username: string;
//Método provicionar para guardar Credenciales para acceder a ARTUS desde el menú.
export function guardarCredenciales(pass: string, user: string) {
  Pass = pass;
  Username = user;
}
export const apiCivarLogin =
  'https://ws.csigroup.com.mx/csi-web-login/token-login.aspx?token=';

// NEW PROD
export const apiCivarMenu = 'https://apis.csigroup.com.mx/quantum/v1/csiapp/userinfoservice';

// DEV
// export const apiCivarMenu = 'https://vs5858pe75.execute-api.us-east-1.amazonaws.com/Prod/v1/csiapp/userinfoservice';

// PRDO
// export const apiCivarMenu = 'https://h5067lrq1k.execute-api.us-east-1.amazonaws.com/csi-aws-csiapp/v1/csiapp/userinfoservice';

export const apiEndpoints = {
  login: '/Login',
  logout: '/Signout',
  getUser: '/UserProfile',
  getPreferences: '/Preferences',
  getTravelList: '/TravelList',
  getTravelDetail: '/TravelDetail',
  getVehicleDetail: '/VehicleDetail',
  getSlides: '/HomeImage',
  getTrackingList: '/TrackingList',
  getTrackingDetail: '/TrackingDetail',
  getShipList: '/ShipList',
  getShipDetail: '/ShipDetail',
  getVehicleLogos: '/Logos',
  getComplaintList: '/ComplaintList',
  getComplaintDetail: '/ComplaintVINDetail',
  getVinsList: '/VehicleList',
  getDamageCatalog: '/DamageCatalogList',
  getNotifications: '/NotificationList',
  getNotificationCounter: '/NotificationCounter',
  getDamageList: '/ComplaintDetail',
  getUserMenu: '/UserMenu',
  updateUserMenuPreferences: '/UserMenu/UpdateBits',
  setSurvillance: '/TravelSuvervillance',
  setNotificationReaded: '/NotificationCounter',
  sendComplaint: '/ComplaintGenerate',
  sendScore: '/TravelScore',
  sendProfile: '/UserProfile',
  getCD: '/VINCDList',
  getYards: '/VINCDYardList',
  getTrans: '/VINCDTransList',
  getVinCDList: '/VINCDVinList',
  getReportType: '/ReportTypesList',
  getReportList: '/ReportByTypeList',
  getReportFile: '/ReportFile',
  getVinMarcas: '/getVinMarcas',
  getVinFamilias: '/getVinFamilias',
  getVinLineas: '/getVinLineas',
  getVinModelos: '/getVinModelos',
  getVinColores: '/getVinColores',
  getLocalidades: '/getLocalidades',
  getVinDealers: '/getVinDealers',
  getSolicitudes: '/getSolicitudes',
  updateSolicitud: '/updateSolicitud',
  getUsuariosDealers: '/getUsuarioDealers',
  getUsuarioVins: '/getUsuarioVins',
  getVinsSolicitudEmbarque: '/getVinsSolicitudEmbarque',
  setSolicitud: '/setSolicitud',
  setSolicitudEmbarque: '/setSolicitudEmbarque',
  getVinsSolicitudEmbarqueActiva: '/getVinsSolicitudEmbarqueActiva',
  setVinsSolicitudEmbarqueCancelar: '/setVinsSolicitudEmbarqueCancelar',
  setUsarioInfo: '/setUsarioInfo',
  NotificationRegister: '/NotificationRegister',
  userChangePassword: '/UserChangePassword',
  getInfoDocuments: '/TravelDocuments',
  getDocument: '/TravelDocument',
  downloadAttributeImage: '/DownloadAttributeImage',
  hasSupport: '/Version/hasSupport',
  userLogin: '/UserLogin',
  SSOLogin: '/SSOLogin'
};

export const consultasCivar = {
  vines: '/Vines',
  vines2: '/Vines2',
  filtros: '/Filtros',
  lab: '/LABConsultas',
  range: '/ListadoNumerico',
  verTodas: '/ConsultaSUP',
  eliminar: '/ConsultaDUP',
  guardar: '/ConsultaIUP',
  actualizar: '/Consulta',
  verUsuarios: '/ConsultaUsuariosPublicados',
  verUna: '/ObtenerDatosConsulta',
  publicar: '/PublicacionIUP',
  buscarUsuarios: '/ConsultaUsuariosDG',
  vinesTodos: '/VinesTodos2',
  guardarDefault: '/ConfiguracionUUP',
  verDefault: '/ObtieneDatosConsultaDefault',
  exportDetalle: '/VinesMapColumnsExcel',
  exportMultiple: '/VinesTodosExcel',
};

export const consultasMatriz = {
  resumen: '/getResumenTrafico',
  cd: '/getCentrosDistribucion',
  estatus: '/getEquiposEstatus',
  economico: '/getEconomicos',
  cliente: '/getClientes',
  detalle: '/getDetalleResumen',
  programacion: '/getProgramacionSemanal',
  updateProgramacion: '/setProgramacionSemanal',
};

export const alertasWss = {
  getAlertas: '/GetReporteAlarmasNvo',
  getDetalleAlertas: '/GetReporteAlarmasDetalleNvo',
  getListaClasificacion: '/GetAlarmasClasificacion',
  updateAlertas: '/UpdateAlarmasDetalleClasificacion',
  getCategorias: '/GetCategorias',
  getEmpresas: '/GetEmpresas',
};

export const tractoresWss = {
  getEconomico: '/GetEconomicoList',
  getConsultaTractor: '/GetConsultaTractor',
  getRastreo: '/getRastreo',
  getMantenimiento: '/getMantenimiento',
};

export const bitacoraErrores = {
  setError: '/SetError',
};

export const idiomasEndpoints = {
  updateIdiomaSelection: "/setUsarioIdiomaInfo",
};

export const dashboardsEndpoints = {
  getReporteIngresos: "/ReporteIngresos",
  getConfigReporteIngresos: "/ReporteIngresos/Config",
  getReporteEstadoFinanciero: "/ReporteEstadoFinanciero",
  getConfigReporteKPI: "/ReporteIndicadorClaveRendimiento/Config",
  getReporteKPITable: "/ReporteIndicadorClaveRendimiento/Tabla",
  getReporteKPIChart: "/ReporteIndicadorClaveRendimiento/Grafica",
  activar: "/ActivacionDashboardFinanciero",
  verificarActivacion: "/VerificarDashboardFinanciero",
  validateCodigoActivacion: "/ValidacionDashboardFinanciero",
  desactivar: "/DesactivarDashboardFinanciero",
  getEmpresas: "/Empresas",
  activarOperativo: "/ActivacionDashboardOperativo",
  verificarActivacionOperativo: "/VerificarDashboardOperativo",
  validateCodigoActivacionOperativo: "/ValidacionDashboardOperativo",
  desactivarOperativo: "/DesactivarDashboardOperativo",
  getConfigReporteOperativoVolumen: "/ReporteOperativoVolumen/Config",
  getReporteOperativoVolumen: "/ReporteOperativoVolumen",
  getReporteOperativoEstaditico: "/ReporteOperativoEstadistico",
  getConfigReporteOpKPITransporte: "/ReporteOperativoKPITransporte/Config",
  getReporteOpKPITransporteTable: "/ReporteOperativoKPITransporte/Tabla",
  getReporteOpKPITransporteChart: "/ReporteOperativoKPITransporte/Grafica",
  getConfigReporteOpKPICD: "/ReporteOperativoKPICD/Config",
  getReporteOpKPICDTable: "/ReporteOperativoKPICD/Tabla",
  getReporteOpKPICDChart: "/ReporteOperativoKPICD/Grafica",
  getDashboardFavorito: "/ObtenerDashboardFavorito",
  setDashboardFavorito: "/AsignarDashboardFavorito",
  getFiltros: '/getFiltros',
  getUnidadesEntregadas: '/KPIUnidadesEntregadas',
  getCarLine: '/KPICarline',
  getTTpromedio: '/KPITTPromedio',
  getTablaTTpromedio: '/KPITTPromedioTabla',
  getIntercambios: '/KPIIntercambios',
  getFrecuenciaEntregas: '/KPIFrecuenciaEntregas',
  getDiasDeSolicitud: '/KPITTFrecuenciaSemana',
  getBarFrecuenciaEntregas: '/KPIFrecuenciaEntregasDias',
  getDashboardBSCFiltros: "/getFiltros",
  getDashboardBSCTable: "/ReporteXPeriodoKPIData/Tabla",
  getDashboardBSCChart: "/ReporteXPeriodoKPI/Grafica",
  captureKPIValDashboardBSC: "/CapturarKPI/Valor",
  captureKPIEstDashboardBSC: "/CapturarKPI/Estatus",
  captureKPIFromFileDashboardBSC: "/CapturarKPI/FromFile",
  getServiciosRealizados: "/KPIServicios",
};

export const patiosWss = {
  getUbicacionCentrosDistribucion: '/GetUbicacionCentrosDistribucion',
  getEstatus: '/GetEstatus',
  getPatios: '/GetPatios',
  getBahias: '/GetBahia',
  searchVin: '/GetVin',
};

export const investorRelations = {
  getDocuments: '/Documents',
  getDownloadUrl: '/Document',
};

export const rateTrips = {
  getDeliveredTravel: '/DeliveredTravel',
  getDetailDeliveredTravel: '/DeliveredTravelDetail',
  postRateTrip: '/RateTravel',
  getDanos: '/catDanos',
  getVinDanos: '/vinDanosList',
  postDanos: '/agregarDanoVIN',
  postEditedDanos: '/editarDanoVIN'
};

export const manageEndpoints = {
  getManageUsers: '/manage/getManageUsers',
  setManageUsers: '/manage/setManageUsers',
};